var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-showcase-overview" },
    [
      _c("p", { staticClass: "caption" }, [
        _vm._v(
          "\n    Showcase is a place that spotlights the extraordinary stories and work happening at UCHealth.\n  "
        ),
      ]),
      _vm._v(" "),
      _vm._isAdminUser
        ? _c(
            "router-link",
            {
              staticClass: "cta-button primary",
              attrs: {
                to: _vm.$generateRoute({
                  name: "showcase.submit",
                  params: {
                    showcaseId: _vm.showcaseId,
                  },
                }),
              },
            },
            [_vm._v("\n    Share Your Work\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }