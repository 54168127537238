var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-item" },
    [
      _c(
        "router-link",
        {
          attrs: {
            to: _vm.$generateRoute({
              name: "showcase.submission",
              params: {
                showcaseId: _vm.showcaseId,
                submissionId: _vm.showcaseSubmission.id,
              },
            }),
            tabindex: "-1",
          },
        },
        [
          _c("div", {
            staticClass: "item-image",
            style: `${
              _vm.showcaseSubmission.featured_thumbnail &&
              `background-image: url(${_vm.showcaseSubmission.featured_thumbnail}); background-position: center`
            }`,
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col_2-12" }, [
        _c(
          "div",
          { staticClass: "submission-link-wrapper" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: _vm.$generateRoute({
                    name: "showcase.submission",
                    params: {
                      showcaseId: _vm.showcaseId,
                      submissionId: _vm.showcaseSubmission.id,
                    },
                  }),
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.showcaseSubmission.title))])]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.subtitle
          ? _c("p", { staticClass: "caption" }, [
              _vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "submission-likes-wrapper" },
          [
            _vm.submissionLikes.indexOf(_vm.showcaseSubmission.id) === -1
              ? _c("Icon", { attrs: { id: "ic_favorite_border" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.submissionLikes.indexOf(_vm.showcaseSubmission.id) !== -1
              ? _c("Icon", {
                  staticClass: "filled",
                  attrs: { id: "ic_favorite" },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showcaseSubmission.likes > 0
              ? _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showcaseSubmission.likes) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }