var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          "\n    Submit to " +
            _vm._s(_vm.$t("features_showcase_general_label")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "caption required" }, [
        _vm._v("\n    All fields are required unless stated.\n  "),
      ]),
      _vm._v(" "),
      _c(
        "FormRoot",
        {
          attrs: {
            "form-id": "showcase-submission",
            "enable-submit": !_vm.submitting,
            "submit-button-text": `${
              _vm.submitting ? "Submitting..." : "Submit to Spotlight"
            }`,
          },
        },
        [
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        Your Information\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "user-info" },
              [
                _c("TextInput", {
                  ref: "firstName",
                  attrs: {
                    model: _vm.firstName,
                    label: "First Name",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.firstName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "lastName",
                  attrs: {
                    model: _vm.lastName,
                    label: "Last Name",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.lastName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "email",
                  attrs: {
                    model: _vm.email,
                    label: "Email Address",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.email = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("SelectInput", {
                  ref: "office",
                  attrs: {
                    label: "Your Office",
                    "custom-classes": "your-office-input",
                    list: _vm.officeSelectList,
                    model: _vm.office,
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.office = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        My Team\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "responsive-3-columns" },
              [
                _vm._l(
                  _vm.departmentSelectList,
                  function (departmentOption, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "radio-option" },
                      [
                        _c("RadioInput", {
                          ref: "department",
                          refInFor: true,
                          attrs: {
                            name: "department",
                            value: departmentOption,
                            model: _vm.department,
                            label: departmentOption.label,
                            required: "true",
                            validate: "true",
                          },
                          on: {
                            "update:model": function ($event) {
                              _vm.department = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "radio-option" },
                  [
                    _c("RadioInput", {
                      ref: "department",
                      attrs: {
                        name: "department",
                        model: _vm.department,
                        value: "other",
                        label: "Other",
                        required: "true",
                        validate: "true",
                      },
                      on: {
                        "update:model": function ($event) {
                          _vm.department = $event
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "other-text" },
                      [
                        _c("TextInput", {
                          attrs: {
                            model: _vm.departmentText,
                            label: "If other please specify",
                          },
                          on: {
                            "update:model": function ($event) {
                              _vm.departmentText = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        What is the scope of this project?\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radio-option" },
              [
                _c("RadioInput", {
                  ref: "scope",
                  attrs: {
                    name: "scope",
                    model: _vm.scope,
                    value: "local",
                    label: "Local",
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.scope = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radio-option" },
              [
                _c("RadioInput", {
                  ref: "scope",
                  attrs: {
                    name: "scope",
                    model: _vm.scope,
                    value: "regional",
                    label: "Regional",
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.scope = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radio-option" },
              [
                _c("RadioInput", {
                  ref: "scope",
                  attrs: {
                    name: "scope",
                    model: _vm.scope,
                    value: "global",
                    label: "Global",
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.scope = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v(
                "\n        What is the intended audience of this project?\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radio-option" },
              [
                _c("RadioInput", {
                  ref: "audience",
                  staticClass: "radiobtn-container",
                  attrs: {
                    name: "audience",
                    model: _vm.audience,
                    value: "internal",
                    label: "Internal",
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.audience = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radio-option" },
              [
                _c("RadioInput", {
                  ref: "audience",
                  attrs: {
                    name: "audience",
                    model: _vm.audience,
                    value: "external",
                    label: "External",
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.audience = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radio-option" },
              [
                _c("RadioInput", {
                  ref: "audience",
                  attrs: {
                    name: "audience",
                    model: _vm.audience,
                    value: "both",
                    label: "Both",
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.audience = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v(
                "\n        What type of project is it? (select all that apply)\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "responsive-3-columns" },
              [
                _c("CheckboxArrayInput", {
                  ref: "type",
                  attrs: {
                    name: "type",
                    model: _vm.type,
                    list: _vm.typeSelectList,
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.type = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-input checkbox-input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.type,
                        expression: "type",
                      },
                    ],
                    attrs: {
                      id: `typeOther_other`,
                      type: "checkbox",
                      role: "checkbox",
                      value: "other",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.type)
                        ? _vm._i(_vm.type, "other") > -1
                        : _vm.type,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.type,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "other",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.type = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.type = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.type = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: `typeOther_other` } }, [
                    _vm._v("Other"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "other-text" },
                  [
                    _c("TextInput", {
                      attrs: {
                        model: _vm.typeText,
                        label: "If other please specify",
                      },
                      on: {
                        "update:model": function ($event) {
                          _vm.typeText = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        Industry (optional)\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "responsive-3-columns" },
              [
                _c("CheckboxArrayInput", {
                  attrs: {
                    name: "industry",
                    model: _vm.industry,
                    list: _vm.industrySelectList,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.industry = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("legend", { staticClass: "subhead-2" }, [
              _vm._v("\n        Capability area (optional)\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "responsive-3-columns" },
              [
                _c("CheckboxArrayInput", {
                  attrs: {
                    name: "capability",
                    model: _vm.capability,
                    list: _vm.capabilitySelectList,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.capability = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "project-info" },
            [
              _c("legend", { staticClass: "subhead-2" }, [
                _vm._v("\n        Project Information\n      "),
              ]),
              _vm._v(" "),
              _c("TextInput", {
                ref: "title",
                attrs: {
                  model: _vm.title,
                  required: "true",
                  validate: "true",
                  placeholder: "Write a Brief Title...",
                  maxlength: _vm.maxTitleChars,
                  label: "Project Title",
                  "help-text": _vm.titleCharsLeft + " characters left",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.title = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "description",
                attrs: {
                  placeholder: "Project Description...",
                  model: _vm.description,
                  required: "true",
                  validate: "true",
                  maxlength: _vm.maxDescriptionChars,
                  rows: 10,
                  label:
                    "Tell us your project’s story (goals, audience, challenges, outputs, etc.)",
                  "help-text": _vm.descriptionCharsLeft + " characters left",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.description = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "impact",
                attrs: {
                  placeholder: "Extraordinary Impact…",
                  model: _vm.impact,
                  required: "true",
                  validate: "true",
                  maxlength: _vm.maxImpactChars,
                  rows: 4,
                  label: "What was the impact / results of your project?",
                  "help-text": _vm.impactCharsLeft + " characters left",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.impact = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("legend", { staticClass: "subhead-2" }, [
                _vm._v("\n        Upload 1-10 images*\n      "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "caption" }, [
                _vm._v(
                  "\n        Upload JPG or PNG photo(s) that are less than 5MB each. The first photo\n        uploaded will be your featured photo. Please use a 4:3 ratio (~840px\n        wide by 468px tall) when possible.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ShowcaseFileUpload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add file(s)",
                  "upload-files-demo": "uploadFilesDemo",
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("legend", { staticClass: "subhead-2" }, [
                _vm._v("\n        Include any relevant links\n      "),
              ]),
              _vm._v(" "),
              _c("showcase-links", {
                ref: "showcaseLinks",
                attrs: {
                  links: _vm.links,
                  placeholder_text: "e.g. Policies",
                  placeholder_url: "e.g. http://www.site.com/link",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }