var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-intro" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v("\n    Overview\n  ")]),
      _vm._v(" "),
      _c("p", { staticClass: "subtitle" }, [
        _vm._v(
          "\n    We’re showcasing the best examples of our brand coming to life from around\n    the globe. Submit your own project and show off a little.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "btn primary",
          attrs: {
            to: _vm.$generateRoute({
              name: "showcase.submit",
              params: {
                showcaseId: _vm.showcaseId,
              },
            }),
          },
        },
        [_vm._v("\n    Share Your Work\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }