var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-showcase-grid" },
    [
      _c("Filters", {
        attrs: {
          route: _vm.route,
          "open-text": "Show Filters",
          "close-text": "Hide Filters",
          collection: "showcase_submissions",
          namespace: "ShowcaseSubmissions",
          local: "true",
          "sort-order": _vm.sortOrder,
        },
      }),
      _vm._v(" "),
      _c("SortControls", {
        attrs: {
          collection: "showcase_submissions",
          namespace: "ShowcaseSubmissions",
          route: _vm.route,
          local: "true",
          "sort-order": _vm.sortOrder,
        },
        on: {
          "update:sortOrder": function ($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function ($event) {
            _vm.sortOrder = $event
          },
        },
      }),
      _vm._v(" "),
      !_vm.submissions.length
        ? [_c("p", [_vm._v("No showcase items.")])]
        : _vm._e(),
      _vm._v(" "),
      _vm.submissions.length
        ? [
            _c(
              "div",
              { staticClass: "showcase-grid-container" },
              [
                _vm._l(_vm.submissions, function (showcaseSubmission) {
                  return [
                    _c(
                      "div",
                      {
                        key: showcaseSubmission.id,
                        staticClass: "showcase-item",
                      },
                      [
                        _vm.isFromAPI(showcaseSubmission)
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  to: _vm.$getRouteObject(showcaseSubmission),
                                  target: "_blank",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "item-image",
                                  style: `${
                                    showcaseSubmission.featured_thumbnail &&
                                    `background: url(${showcaseSubmission.featured_thumbnail}) center center no-repeat; background-size: cover;`
                                  }`,
                                }),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.$getRouteObject(showcaseSubmission),
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "item-image",
                                  style: `${
                                    showcaseSubmission.featured_thumbnail &&
                                    `background: url(${showcaseSubmission.featured_thumbnail}) center center no-repeat; background-size: cover;`
                                  }`,
                                }),
                              ]
                            ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col_2-12" }, [
                          _c(
                            "div",
                            { staticClass: "description" },
                            [
                              _vm.isFromAPI(showcaseSubmission)
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.$getRouteObject(
                                          showcaseSubmission
                                        ),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(showcaseSubmission.title) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "router-link",
                                    {
                                      staticClass: "title",
                                      attrs: {
                                        to: _vm.$getRouteObject(
                                          showcaseSubmission
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(showcaseSubmission.title) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("div", { staticClass: "department" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getDepartmentName(
                                        showcaseSubmission.id
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "likes" },
                                [
                                  _vm.submissionLikes.indexOf(
                                    showcaseSubmission.id
                                  ) === -1
                                    ? _c("Icons", {
                                        attrs: { name: "showcase-favorite" },
                                      })
                                    : _c("Icons", {
                                        attrs: {
                                          name: "showcase-favorite-fill",
                                        },
                                      }),
                                  _vm._v(" "),
                                  showcaseSubmission.likes > 0
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(showcaseSubmission.likes) +
                                            "\n                "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("Pagination", {
        attrs: { "parent-pagination": _vm.pagination },
        on: {
          onNext: _vm.onNext,
          onPrevious: _vm.onPrevious,
          onGoto: _vm.onGoto,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }