var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-showcase-grid" },
    [
      _c("Filters", {
        attrs: {
          collection: "showcase_submissions",
          namespace: "ShowcaseSubmissions",
          route: _vm.route,
          local: "true",
          "sort-order": _vm.sortOrder,
        },
      }),
      _vm._v(" "),
      _c("SortControls", {
        attrs: {
          collection: "showcase_submissions",
          namespace: "ShowcaseSubmissions",
          route: _vm.route,
          local: "true",
          "sort-order": _vm.sortOrder,
        },
        on: {
          "update:sortOrder": function ($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function ($event) {
            _vm.sortOrder = $event
          },
        },
      }),
      _vm._v(" "),
      !_vm.submissions.length
        ? [_c("p", [_vm._v("No showcase items.")])]
        : _vm._e(),
      _vm._v(" "),
      _vm.submissions.length
        ? [
            _c(
              "div",
              { staticClass: "showcase-grid-container" },
              [
                _vm._l(_vm.submissions, function (showcaseSubmission) {
                  return [
                    _c("grid-item", {
                      key: showcaseSubmission.id,
                      attrs: { "showcase-submission": showcaseSubmission },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "spacer-20" }),
      _vm._v(" "),
      _c("Pagination", {
        attrs: { "parent-pagination": _vm.pagination },
        on: {
          onNext: _vm.onNext,
          onPrevious: _vm.onPrevious,
          onGoto: _vm.onGoto,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }