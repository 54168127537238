var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-slider" },
    [
      _vm.title
        ? _c("h1", { staticClass: "title" }, [
            _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-carousel",
        {
          attrs: {
            id: "slide-" + _vm.submissionId,
            interval: 0,
            controls: _vm.numberOfSlides > 1,
            indicators: _vm.indicatorsBoolean,
          },
          on: {
            "sliding-start": _vm.onSlideStart,
            "sliding-end": _vm.onSlideEnd,
          },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        _vm._l(_vm.slides, function (slide, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "b-carousel-slide",
                { attrs: { "img-src": slide.img } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("div", {
                                staticClass: "hide-mobile",
                                staticStyle: { height: "100px" },
                              }),
                              _vm._v(" "),
                              slide.title
                                ? _c("h1", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(slide.title) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              slide.subtitle
                                ? _c("h3", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(slide.subtitle) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              typeof slide.likes === "number"
                                ? _c(
                                    "div",
                                    { staticClass: "favorites" },
                                    [
                                      _vm.isLiked(slide.submission_id)
                                        ? _c("icons", {
                                            attrs: {
                                              name: "showcase-favorite-fill",
                                            },
                                          })
                                        : _c("icons", {
                                            attrs: {
                                              name: "showcase-favorite",
                                            },
                                          }),
                                      _vm._v(" "),
                                      slide.likes > 0
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(slide.likes) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              slide.link_text
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "carousel-link",
                                      staticStyle: { "margin-bottom": "105px" },
                                      attrs: {
                                        to: slide.link_url,
                                        target: slide.link_target,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.ctaButtonText) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }