/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'rounded',
    custom: {
      ic_search: {
        viewbox: '0 0 35 35',
        content: '<g fill="none" fill-rule="evenodd"><path d="M-4-5h44v44H-4z"/><path fill="currentColor" d="M20.75.5C28.344.5 34.5 6.656 34.5 14.25S28.344 28 20.75 28a13.7 13.7 0 01-9.053-3.4l-9.549 9.548-1.296-1.296 9.548-9.55A13.695 13.695 0 017 14.25C7 6.656 13.156.5 20.75.5zm0 1.833c-6.581 0-11.917 5.336-11.917 11.917 0 6.581 5.336 11.917 11.917 11.917 6.581 0 11.917-5.336 11.917-11.917 0-6.581-5.336-11.917-11.917-11.917z"/></g>'
      },
      ic_arrow_forward: {
        viewbox: '0 0 39 16',
        content: '<g fill="none" fill-rule="evenodd"><path d="M3-4h24v24H3z"/><path fill="currentColor" d="M31 0l-1.41 1.41L35.17 7H0v2h35.17l-5.58 5.59L31 16l8-8z"/></g>'
      }
    }
  }
}
