var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormRoot",
    {
      attrs: {
        "form-id": "showcase-submission",
        "enable-submit": true,
        "show-save-button": false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contact-info-wrapper" },
        [
          _c("h2", { staticClass: "form-category" }, [
            _vm._v("\n      Contact Information\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "firstName",
            attrs: {
              model: _vm.firstName,
              label: "First Name",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.firstName = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "lastName",
            attrs: {
              model: _vm.lastName,
              label: "Last Name",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.lastName = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "email",
            staticClass: "responsive-margin-right",
            attrs: {
              model: _vm.email,
              label: "Email",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.email = $event
              },
            },
          }),
          _vm._v(" "),
          _c("SelectInput", {
            ref: "office",
            attrs: {
              label: "Department",
              "custom-classes": "your-office-input",
              list: _vm.departmentSelectList,
              model: _vm.department,
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.department = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    What is the intended audience of this project? *\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "radio-option" },
        [
          _c("RadioInput", {
            ref: "audience",
            attrs: {
              name: "audience",
              model: _vm.audience,
              value: "internal",
              label: "Internal",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.audience = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "radio-option" },
        [
          _c("RadioInput", {
            ref: "audience",
            attrs: {
              name: "audience",
              model: _vm.audience,
              value: "external",
              label: "External",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.audience = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "radio-option" },
        [
          _c("RadioInput", {
            ref: "audience",
            attrs: {
              name: "audience",
              model: _vm.audience,
              value: "both",
              label: "Both",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.audience = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v(
          "\n    What type of project is it? (select all that apply) *\n  "
        ),
      ]),
      _vm._v(" "),
      _c("CheckboxArrayInput", {
        ref: "type",
        attrs: {
          name: "type",
          model: _vm.type,
          list: _vm.typeSelectList,
          required: "true",
          validate: "true",
        },
        on: {
          "update:model": function ($event) {
            _vm.type = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category mb-0" }, [
        _vm._v("\n    Project Information\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title-input" },
        [
          _c("TextInput", {
            ref: "title",
            attrs: {
              model: _vm.title,
              required: "true",
              validate: "true",
              label: "Project Title",
              maxlength: _vm.maxTitleChars,
            },
            on: {
              "update:model": function ($event) {
                _vm.title = $event
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.titleCharsLeft) + " characters left"),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Project Description *\n  "),
      ]),
      _vm._v(" "),
      _c("TextareaInput", {
        ref: "description",
        attrs: {
          placeholder: "(Goals, audience, challenges, outputs, etc.)",
          model: _vm.description,
          required: "true",
          validate: "true",
          maxlength: _vm.maxDescriptionChars,
          rows: 10,
        },
        on: {
          "update:model": function ($event) {
            _vm.description = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c("small", [
          _vm._v(_vm._s(_vm.descriptionCharsLeft) + " characters left"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    What was the impact/result of your project? *\n  "),
      ]),
      _vm._v(" "),
      _c("TextareaInput", {
        ref: "impact",
        attrs: {
          placeholder: "Project Impact",
          model: _vm.impact,
          required: "true",
          validate: "true",
          maxlength: _vm.maxImpactChars,
          rows: 4,
        },
        on: {
          "update:model": function ($event) {
            _vm.impact = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c("small", [_vm._v(_vm._s(_vm.impactCharsLeft) + " characters left")]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Upload Related Images (up to 10) *:\n  "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "info" }, [
        _vm._v(
          "\n    Photographs should be less than 5MB in .jpg or .png format, and ideally 4:3 ratio.\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.fileHashCollection && _vm.fileHashCollection.length
        ? [
            _c("ShowcaseFileUpload", {
              ref: "files",
              attrs: {
                "upload-button-text": "Add File(s)",
                "upload-button-text-color": "#222428",
                "upload-button-border-styles": "1px solid #d8d8d8",
                "upload-item-background-color": "#f9f9f9",
                "upload-button-width": "uploadButtonWidth",
                "upload-button-height": "uploadButtonHeight",
                "upload-button-padding": "20px 40px",
                "upload-files-demo": "uploadFilesDemo",
                "upload-button-color": "#fff",
                "font-color": "#222428",
                "initial-file-hash-collection": _vm.fileHashCollection,
              },
              on: { "send-hash-collection": _vm.onHashCollectionReady },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Related Links\n  "),
      ]),
      _vm._v(" "),
      _c("showcase-links", {
        ref: "showcaseLinks",
        attrs: {
          links: _vm.links,
          placeholder_text: "e.g. Policies",
          placeholder_url: "e.g. http://www.site.com/link",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }