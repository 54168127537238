var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "filter showcase-filters" }, [
    _c("div", { staticClass: "filter-list-controls" }, [
      _c(
        "form",
        {
          staticClass: "search-form",
          attrs: {
            id: "search-form",
            role: "search",
            "aria-label": "Library Search Form",
          },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSearch.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "search-term" }, [
            _c(
              "label",
              {
                staticClass: "offscreen",
                attrs: { for: `search-library_${_vm.id}` },
              },
              [_vm._v("Search Library")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              attrs: {
                id: `search-library_${_vm.id}`,
                type: "search",
                placeholder: _vm.placeholderText,
                name: `search-library_${_vm.id}`,
                "aria-label": "Showcase Search",
              },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "search-submit" }, [
            _c(
              "button",
              {
                attrs: {
                  type: "submit",
                  title: _vm.$t("forms_search_field_label"),
                  value: "Search Assets",
                },
              },
              [_c("icon", { attrs: { id: "ic_search" } })],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "filter-toggle" }, [
        _c(
          "button",
          {
            staticClass: "primary filter-button",
            attrs: { type: "button", title: _vm.toggleText },
            on: {
              click: function ($event) {
                _vm.showFilter = !_vm.showFilter
              },
            },
          },
          [
            _c("icon", { attrs: { id: _vm.filterIcon } }),
            _vm._v("\n        " + _vm._s(_vm.toggleText) + "\n      "),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.showFilter
      ? _c(
          "section",
          { staticClass: "filter-list" },
          [
            _vm._l(_vm.filters, function (c) {
              return [
                _c(
                  "div",
                  { key: `category-${c.id}`, staticClass: "filter-categories" },
                  [
                    _c("h4", [_vm._v(_vm._s(c.label))]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      [
                        _vm._l(_vm.getFilters(c.id), function (t) {
                          return [
                            _c("li", { key: `filter-${t.id}` }, [
                              _c("div", { staticClass: "checkbox-input" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedFilters,
                                      expression: "selectedFilters",
                                    },
                                  ],
                                  attrs: {
                                    id: `filterChoice_${_vm.id}_${t.id}`,
                                    type: "checkbox",
                                    name: `filterChoice_${_vm.id}`,
                                  },
                                  domProps: {
                                    value: t.id,
                                    checked: Array.isArray(_vm.selectedFilters)
                                      ? _vm._i(_vm.selectedFilters, t.id) > -1
                                      : _vm.selectedFilters,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.selectedFilters,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = t.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.selectedFilters = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.selectedFilters = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.selectedFilters = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  directives: [
                                    {
                                      name: "sanitize",
                                      rawName: "v-sanitize",
                                      value: t.label,
                                      expression: "t.label",
                                    },
                                  ],
                                  attrs: {
                                    for: `filterChoice_${_vm.id}_${t.id}`,
                                    tabindex: "0",
                                  },
                                  on: {
                                    keydown: function ($event) {
                                      return _vm.$triggerClickEvent(
                                        $event,
                                        `filterChoice_${_vm.id}_${t.id}`
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "secondary clear-filters",
                attrs: {
                  type: "button",
                  title: _vm.$t("filters_actions_reset_label"),
                },
                on: {
                  click: function ($event) {
                    _vm.selectedFilters = []
                  },
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("filters_actions_reset_label")) +
                    "\n    "
                ),
              ]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }