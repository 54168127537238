
import { mapState } from 'vuex'

export default {
  name: 'ShowcaseMixin',
  computed: {
    ...mapState({
      featuredSubmissions: state => state.ShowcaseSubmissionsFeatured.showcase_submissions,
      submissionFilters: state => state.ShowcaseSubmissions.filterParameters,
      submissionLikes: state => state.ShowcaseSubmissionLikes.showcase_submission_likes,
      submissionPagination: state => state.ShowcaseSubmissions.pagination,
    }),
    showcase () {
      return this.showcases?.find(item => item.id === this.showcaseId) ?? null
    },
    showcaseId () {
      return parseInt(this.$route.params.showcaseId)
    },
    showcases () {
      return this.$store.state.Showcases.showcases
    },
    submission () {
      return this.submissions?.find(item => item.id === this.submissionId) ?? null
    },
    submissionId () {
      return parseInt(this.$route.params.submissionId)
    },
    submissions () {
      return this.$store.state.ShowcaseSubmissions.showcase_submissions
    },
    featured: {
      get () {
        return this.$store.state.ShowcaseSubmissions.formData.featured
      },
      set (value) {
        this.$store.state.ShowcaseSubmissions.formData.featured = value
      }
    },
    isFormUpdated: {
      get () {
        return this.$store.state.Global.formUpdated?.['showcase-submission'] ?? false
      },
      set (value) {
        this.$set(this.$store.state.Global.formUpdated, 'showcase-submission', value)
      }
    },
    eventType: {
      get () {
        return this.$store.state.ShowcaseSubmissions.eventType
      },
      set (value) {
        this.$store.state.ShowcaseSubmissions.eventType = value
      }
    }
  },
  methods: {
    openLink () {
      let route = this.$router.resolve(`/showcase/${this.showcaseId}/submission/${this.submissionId}`)

      window.open(route.href, '_blank')

      this.eventType = ''
    },
    loadShowcases () {
      this.$store.dispatch('Showcases/getNestedProperty', {
        collection: 'showcases',
        element: 'showcases',
        url: `showcases/${this.showcaseId}`
      })
    },
    loadComments () {
      this.$store.dispatch('ShowcaseSubmissionComments/getProperty', {
        collection: 'showcase_submission_comments',
        url: `/submissions/${this.submissionId}/comments`
      })
    },
    loadSubmission () {
      return this.$store.dispatch('ShowcaseSubmissions/getNestedProperty', {
        collection: 'showcase_submissions',
        element: 'showcase_submissions',
        url: `/showcases/${this.showcaseId}/submissions/${this.submissionId}`
      })
    },
    loadSubmissions () {
      this.$store.dispatch('ShowcaseSubmissions/getProperty', {
        collection: 'showcase_submissions',
        url: `showcases/${this.showcaseId}/submissions`,
        params: {
          filterParameters: this.submissionFilters,
          pagination: this.submissionPagination
        }
      })
    }
  }
}
