var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-links" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.localLinks, function (link, index) {
        return _c("div", { key: index, staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localLinks[index].text,
                  expression: "localLinks[index].text",
                },
              ],
              staticClass: "text",
              attrs: { type: "text", placeholder: _vm.placeholder_text },
              domProps: { value: _vm.localLinks[index].text },
              on: {
                blur: function ($event) {
                  return _vm.validateLinks()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.localLinks[index], "text", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.validation[index] !== undefined && !_vm.validation[index].text
              ? _c("div", { staticClass: "form-input" }, [
                  _c("div", { staticClass: "error" }, [
                    _vm._v("\n          This field is required\n        "),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localLinks[index].url,
                  expression: "localLinks[index].url",
                },
              ],
              staticClass: "url",
              attrs: { type: "text", placeholder: _vm.placeholder_url },
              domProps: { value: _vm.localLinks[index].url },
              on: {
                blur: function ($event) {
                  return _vm.validateLinks()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.localLinks[index], "url", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.validation[index] !== undefined && !_vm.validation[index].url
              ? _c("div", { staticClass: "form-input" }, [
                  _c("div", { staticClass: "error" }, [
                    _vm._v("\n          Wrong URL format\n        "),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-1 align-self-center",
              on: {
                click: function ($event) {
                  _vm.localLinks.length > 1 ? _vm.removeLink(index) : null
                },
              },
            },
            [
              _vm.localLinks.length > 1
                ? _c("icon", {
                    staticClass: "large remove-link",
                    attrs: { id: "ic_close" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "add-button cta-button",
          class: { secondary: !_vm._isAdminArea, default: _vm._isAdminArea },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.createLink()
            },
          },
        },
        [_vm._v("\n    Add link\n  ")]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row link-title" }, [
      _c("div", { staticClass: "col" }, [_vm._v("\n      Link title\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._v("\n      Link url\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }