var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "client-showcase-carousel carousel-container-component simple",
    },
    [
      _c(
        "b-carousel",
        {
          attrs: {
            id: _vm.id,
            interval: _vm.interval,
            controls: _vm.showControls,
            indicators: _vm.indicatorsBoolean,
            "aria-label": "Showcase slider",
          },
          on: {
            "sliding-start": _vm.onSlideStart,
            "sliding-end": _vm.onSlideEnd,
          },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        _vm._l(_vm.slides, function (s, index) {
          return _c("b-carousel-slide", {
            key: index,
            scopedSlots: _vm._u(
              [
                {
                  key: "img",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "img-wrap" }, [
                        _c("img", {
                          ref: "carouselImg",
                          refInFor: true,
                          staticClass: "d-block img-fluid",
                          attrs: {
                            src:
                              s.mime_type && s.mime_type.includes("image/gif")
                                ? s.src
                                : s.img,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        s.mime_type && s.mime_type.includes("video/")
                          ? _c(
                              "button",
                              {
                                staticClass: "play-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.playVideo(s.src)
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  attrs: { id: "ic_play_circle_filled" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.currentSlide.title ||
      _vm.currentSlide.subtitle ||
      _vm.currentSlide.link_url
        ? _c(
            "div",
            { staticClass: "carousel-caption" },
            [
              _vm.currentSlide.title
                ? _c("h1", [
                    _vm._v(
                      "\n      " + _vm._s(_vm.currentSlide.title) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentSlide.subtitle
                ? _c("h3", [
                    _vm._v(
                      "\n      " + _vm._s(_vm.currentSlide.subtitle) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              typeof _vm.currentSlide.likes === "number"
                ? _c(
                    "div",
                    { staticClass: "favorites" },
                    [
                      _vm.isLiked(_vm.currentSlide.submission_id)
                        ? _c("Icon", {
                            staticClass: "filled",
                            attrs: { id: "ic_favorite" },
                          })
                        : _c("Icon", { attrs: { id: "ic_favorite_border" } }),
                      _vm._v(" "),
                      _vm.currentSlide.likes > 0
                        ? _c("span", [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.currentSlide.likes) +
                                "\n      "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentSlide.link_url
                ? _c(
                    "router-link",
                    {
                      staticClass: "carousel-link",
                      attrs: {
                        to: _vm.currentSlide.link_url,
                        target: _vm.currentSlide.link_target,
                        "aria-label": _vm.currentSlide.title
                          ? _vm.currentSlide.title
                          : null,
                      },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.ctaButtonText) + "\n    ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }