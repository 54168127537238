var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        { admin: _vm.isAdminRoute },
        { "video-file": _vm.isVideo },
        "file-indicator",
      ],
    },
    [
      _vm.isVideo
        ? _c("icons", {
            staticClass: "video-icon",
            attrs: { name: "video-icon" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isAdminRoute && !_vm.isUploading && !_vm.isVideoJustUploaded
        ? _c("img", { attrs: { src: _vm.file.thumbnail, alt: _vm.file.name } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isAdminRoute && _vm.isUploading
        ? _c("div", { staticClass: "file-name-status" }, [
            _c("div", { staticClass: "file-name" }, [
              _vm._v("\n      Loading...\n    "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAdminRoute
        ? _c(
            "div",
            { staticClass: "file-name-status" },
            [
              _c("div", {
                staticClass: "file-name",
                domProps: {
                  textContent: _vm._s(_vm.truncateText(_vm.file.name)),
                },
              }),
              _vm._v(" "),
              _c("transition", { attrs: { name: "status-anim" } }, [
                _vm.isUploadingData && !_vm.stopUpload
                  ? _c("div", { staticClass: "status-bar-wrapper" }, [
                      _c("div", { staticClass: "status-bar" }, [
                        _c("div", { staticClass: "status-worm" }),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isUploading && _vm.allowDelete
        ? _c(
            "button",
            {
              staticClass: "x-button",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.cancelUpload()
                },
              },
            },
            [
              _vm.isAdminRoute
                ? [_c("Icons", { attrs: { name: "delete" } })]
                : [_c("icon", { attrs: { id: "ic_close" } })],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }